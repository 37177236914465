const anetDimebar = "mcc512ad16_anetDimebar";
const anetDimebarAbs = anetDimebar + " " + "mcc512ad16_anetDimebarAbs";
const wrapper = "mcc512ad16_wrapper";
const siteNav = "mcc512ad16_siteNav";
const primaryNav = "mcc512ad16_primaryNav";
const supportInfo = "mcc512ad16_supportInfo";
const downloadBtn = "mcc512ad16_downloadBtn";
const anetLogo = "mcc512ad16_anetLogo";
const langSelectBar = "mcc512ad16_langSelectBar";
const currentLang = "mcc512ad16_currentLang";
export default {
    anetDimebar,
anetDimebarAbs,
wrapper,
siteNav,
primaryNav,
supportInfo,
downloadBtn,
anetLogo,
langSelectBar,
currentLang
};

export {
    anetDimebar,
anetDimebarAbs,
wrapper,
siteNav,
primaryNav,
supportInfo,
downloadBtn,
anetLogo,
langSelectBar,
currentLang
};