const footer = "mc7ff6cbbf_footer";
const halfFooter = footer + " " + "mc7ff6cbbf_halfFooter";
const footerLight = "mc7ff6cbbf_footerLight";
const pageFooterNav = "mc7ff6cbbf_pageFooterNav";
const navChildBar = "mc7ff6cbbf_navChildBar";
const socialBar = "mc7ff6cbbf_socialBar";
const followUs = "mc7ff6cbbf_followUs";
const socialIcon = "mc7ff6cbbf_socialIcon";
const youtube = socialIcon + " " + "mc7ff6cbbf_youtube";
const facebook = socialIcon + " " + "mc7ff6cbbf_facebook";
const twitter = socialIcon + " " + "mc7ff6cbbf_twitter";
const twitch = socialIcon + " " + "mc7ff6cbbf_twitch";
const instagram = socialIcon + " " + "mc7ff6cbbf_instagram";
const tumblr = socialIcon + " " + "mc7ff6cbbf_tumblr";
const flickr = socialIcon + " " + "mc7ff6cbbf_flickr";
const rss = socialIcon + " " + "mc7ff6cbbf_rss";
const pof = "mc7ff6cbbf_pof";
const legalFooter = "mc7ff6cbbf_legalFooter";
const logos = "mc7ff6cbbf_logos";
const logo = "mc7ff6cbbf_logo";
const nc = logo + " " + "mc7ff6cbbf_nc";
const anet = logo + " " + "mc7ff6cbbf_anet";
const anetpof = anet + " " + "mc7ff6cbbf_anetpof";
const rating = "mc7ff6cbbf_rating";
const pegi = "mc7ff6cbbf_pegi";
const esrb = "mc7ff6cbbf_esrb";
const usk = "mc7ff6cbbf_usk";
const navCleanup = "mc7ff6cbbf_navCleanup";
const pageFooterNavContainer = "mc7ff6cbbf_pageFooterNavContainer";
export default {
    footer,
halfFooter,
footerLight,
pageFooterNav,
navChildBar,
socialBar,
followUs,
socialIcon,
youtube,
facebook,
twitter,
twitch,
instagram,
tumblr,
flickr,
rss,
pof,
legalFooter,
logos,
logo,
nc,
anet,
anetpof,
rating,
pegi,
esrb,
usk,
navCleanup,
pageFooterNavContainer
};

export {
    footer,
halfFooter,
footerLight,
pageFooterNav,
navChildBar,
socialBar,
followUs,
socialIcon,
youtube,
facebook,
twitter,
twitch,
instagram,
tumblr,
flickr,
rss,
pof,
legalFooter,
logos,
logo,
nc,
anet,
anetpof,
rating,
pegi,
esrb,
usk,
navCleanup,
pageFooterNavContainer
};